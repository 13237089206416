import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    AuthService,
    CurrentUser,
    PrivateEmailVerificationMode,
} from '@interacta-shared/data-access-auth';
import { ENVIRONMENT } from '@interacta-shared/data-access-configuration';
import { TipService } from '@interacta-shared/feature-tip';
import { verifyPrivateEmail } from '@modules/app-routing/routes';
import { SharedProfile, UserExtendedDeserialize } from '@modules/core';
import { map, Observable, tap, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PrivateEmailService {
    private readonly baseUrlCore = `${inject(ENVIRONMENT).apiBasePath.common}/core`;
    private readonly authService = inject(AuthService);
    private readonly tipService = inject(TipService);
    private readonly router = inject(Router);
    private readonly http = inject(HttpClient);

    updateCurrentUserPrivateEmail(
        privateEmail: CurrentUser['privateEmail'],
    ): Observable<SharedProfile> {
        const currUser = this.authService.currentUserData();

        if (!currUser) {
            return throwError(
                () =>
                    'The user must be logged in order to change the private email',
            );
        }

        return this.updatePrivateEmail(privateEmail, currUser.occToken).pipe(
            tap(({ occToken }) => {
                this.authService.setPrivateEmail(privateEmail, occToken);
            }),
        );
    }

    verifyCurrentUserPrivateEmail(otpCode: string): Observable<unknown> {
        const currUser = this.authService.currentUserData();

        if (!currUser) {
            return throwError(
                () =>
                    'The user must be logged in order to verify the private email',
            );
        }

        return this.verifyPrivateEmail(otpCode).pipe(
            tap(() => {
                this.authService.setPrivateEmailVerified();
            }),
        );
    }

    askVerifyCurrentUserPrivateEmail(): void {
        const currentUser = this.authService.currentUserData();
        if (
            currentUser &&
            currentUser.privateEmail &&
            !currentUser.privateEmailVerified &&
            !currentUser.privateEmailVerificationRequired
        ) {
            this.tipService
                .openProTip({
                    title: 'PRIVATE_EMAIL.TIP_VERIFY_EMAIL.TITLE',
                    message: 'PRIVATE_EMAIL.TIP_VERIFY_EMAIL.DESCRIPTION',
                    closeBehavior: 'manual',
                    tipActions: [
                        {
                            id: 'goToVerifyPrivateEmail',
                            label: 'PRIVATE_EMAIL.TIP_VERIFY_EMAIL.ACTION',
                            data: {},
                        },
                    ],
                })
                .subscribe((action) => {
                    if (action !== 'close')
                        this.router.navigate([`/${verifyPrivateEmail}`]);
                });
        }
    }

    resendPrivateEmailVerification(): Observable<unknown> {
        return this.http.post(
            `${this.baseUrlCore}/user-profile/info/private-email/force-verification`,
            { privateEmailVerificationMode: PrivateEmailVerificationMode.OTP },
        );
    }

    private updatePrivateEmail(
        privateEmail: SharedProfile['privateEmail'],
        occToken: SharedProfile['occToken'],
    ): Observable<SharedProfile> {
        return this.http
            .put<any>(`${this.baseUrlCore}/user-profile/info/private-email`, {
                privateEmail,
                occToken,
                privateEmailVerificationMode: PrivateEmailVerificationMode.OTP,
            })
            .pipe(
                map((res) => UserExtendedDeserialize.userProfileDetails(res)),
            );
    }

    private verifyPrivateEmail(otpCode: string): Observable<unknown> {
        return this.http.post(
            `${this.baseUrlCore}/user-profile/info/private-email/verify`,
            { verifyOtpCode: otpCode },
        );
    }
}
